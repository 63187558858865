div.copyright {
    margin: 4rem var(--readerMarginRight) 2rem var(--readerMarginLeft);

    font-family: var(--sans-serif);
    display: flex;
    flex-direction: column;
    align-items: center;

    div.container {
        text-align: right;
        div.content {
            background-color: var(--bgMid);
            border-radius: 14px;
            display: flex;
            flex-direction: unset;
            flex-grow: 0;
            flex-wrap: unset;
            padding: 8px;
            div.c {
                font-size: 24px;
                font-weight: 200;
                margin-right: 4px;
            }
            div.year {
                font-size: 20px;
                font-weight: 200;
                margin-left: 6px;
                padding-right: 2px;
            }
            div.text {
                width: 100%;
                font-size: 16px;
                background-color: var(--bg);
                color: var(--fg);
                padding: 6px 15px 7px 15px;
                border-radius: 8px;
                text-align: center;
            }
        }
        div.reactions {
            display: flex;
            justify-content: flex-end;
            div.chart {
                margin-right: 14px;
                margin-left: 14px;
                // background-color: red;
                display: inline-flex;
                align-items: center;
                font-size: 12px;
                font-weight: 700;
                a {
                    text-decoration: none;
                    span.top { color: #5E2C6B; }
                    span.z { color: #A500FF; }
                    span.h {color: #FF00D0; }
                    span.o { color: #FFC500; }
                    span.p { color: #5CBBFF; }
                }
            }
            div.likes {
                background-color: var(--bgMid);
                padding: 1px 12px 5px 22px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                display: inline-flex;
                align-items: center;
                margin-right: 14px;
                button.like {
                    padding: 0;
                    border-radius: 0;
                    background-color: unset;
                    height: 30px;
                    svg.icon {
                        width: 24px;
                        height: 24px;
                    }
                }
                div.counter {
                    font-size: 14px;
                    line-height: 14px;
                    margin-left: 6px;
                    min-width: 18px;
                    text-align: left;
                    &.dislike {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}


@media (max-device-width: 480px) and (orientation: portrait) {
    div.copyright0 div.reactions button.like svg.icon {
        width: 36px;
        height: 36px;
    }
}

@media (prefers-color-scheme: dark)  {
    div.copyright {
        div.container {
            div.reactions {
                div.chart {
                    a {
                        span.top { color: #412950; }
                        span.z { color: #7C25BD; }
                        span.h {color: #AD3296; }
                        span.o { color: #897020; }
                        span.p { color: #3C5E91; }
                    }
                }
            }
        }
    }
}
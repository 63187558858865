
div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    div.author {
        margin-left: var(--readerMarginLeft);
        margin-right: var(--readerMarginRight);

        font-family: var(--serif);

        h1, h2 {
            font-family: var(--sans-serif);
        }

        p {
            font-size: 18px;
            max-width: 42rem;
        }
    }
}

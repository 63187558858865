
div.test {
    display: inline-block;
    > div {
        border-radius: 5px;
        padding: 10px;
    }
    div.stateA {
        background-color: coral;
        width: 150px;
    }
    div.stateB {
        background-color: cornflowerblue;
        width: 200px;
        border-radius: 20px;
    }
}

div.scribble.create {
    textarea {
        width: 88%;
        height: 400px;
    }
}

div.scribble {
    svg.scribble {
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linecap: round;
        stroke-linejoin: round;
        g.paths {
            stroke: var(--fg);
            fill: none;
        }
    }
}

div.scribble.on.comments {
    svg.scribble {
        width: 240px;
    }
}

html {
    height: -webkit-fill-available;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;

    /* mobile viewport bug fix */
    /* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
    min-height: 100vh;
    min-height: -webkit-fill-available;

    display: flex;
    flex-direction: column;

    background-color: var(--bg);
    color: var(--fg);
}

div#root {
    flex-grow: 1;
}

/* @media (prefers-color-scheme: dark) {
    div#root { background-color: blueviolet; }
} */

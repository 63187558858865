
div.featured {
    height: 157px;
    position: relative;
    // padding-left: var(--readerMarginLeft);
    button {
        position: absolute;
        top: 47px; left: var(--readerMarginLeft);
    }
    svg.featured {
        position: absolute;
        z-index: 7;
        left: 70px;
        width: 220px;
        height: 157px;
        path.outline {
            fill: var(--bg);
            fill-opacity: 0.67;
        }
    }
}

button {
    cursor: pointer;
    border: 1px solid var(--bgDim);
    border-radius: 13px;
    padding: 2px;
    background-color: var(--bg);
    font-family: var(--sans-serif);
    > div {
        // display: inline-flex;
        background-color: var(--bgMid);
        color: var(--fg);
        border-radius: 10px;
        padding: 6px 12px;
        font-size: 18px;
        line-height: calc(18px + 18px/3);
    }

    &.small {
        border: none;
        border-radius: 6px;
        background-color: var(--bgMid);
        padding: 0;
        > div {
            background-color: unset;
            color: unset;
            font-size: 14px;
            padding: 3px 11px 4px 11px;
            line-height: calc(15px + 15px/3);
        }
        &.confirm {
            margin-right: 7px;
            &:last-child { margin-right: 0; }
            &.no {
                background-color: var(--bgDim);
            }
        }
        &:disabled {
            cursor: default;
            border: none;
            background-color: var(--bgLight);
            color: var(--bg);
            > div {
                color: unset;
                background-color: unset;
                // background-color: var(--bgLight);
            }
        }
    }

    &.action {
        border-radius: 15px;
        > div {
            border-radius: 12px;
            font-size: 24px;
            padding: 8px 16px;
            line-height: calc(24px + 24px/3);
        }
    }
    &.cancel {
        border-radius: 0;
        > div {
            padding: 7px 16px;
            border-radius: 0;
            font-size: 22px;
        }
    }
    &.submit {
        border: 1px solid var(--bgAccent);
        > div {
            background-color: var(--bgAccent);
            color: var(--fgAccent);
            padding: 8px 22px;
        }
    }
    &:disabled, &.submit:disabled {
        cursor: default;
        border-style: dotted;
        border: 1px solid var(--bgDim);
        > div {
            background-color: var(--bgLight);
            color: var(--bg);
            // background-color: var(--bgLight);
        }
    }
}

div.bradio {
    // position: relative;
    font-family: var(--sans-serif);
    display: flex;
    div.option {
        padding: 6px 12px;
        cursor: pointer;
        &.checked {
            position: relative;
            color: var(--fgAccent);
        }
    }
    div.cursor {
        position: absolute;
        background-color: var(--bgAccent);
        width: 100%;
        left: 10px; top: 10px;
        padding: 6px 12px;
        z-index: -1;
    }
}

div.vradio {
    display: flex;
    font-family: var(--sans-serif);
    div.option {
        display: flex;
        margin-left: 1.125rem;
        margin-right: 0.5rem;
        &:last-child {
            margin-right: unset;
        }
        cursor: pointer;
        &.checked {
            cursor: unset;
            margin-left: 0;
            svg {
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linecap: round;
                stroke-miterlimit: 1.5;
                width: 1rem;
                height: 1rem;
                margin-right: .125rem;
                path {
                    stroke: var(--bgAccent);
                    fill: none;
                    stroke-width: 5px;
                }
            }
        }
    }
}

div.anchor {
    font-size: 1px; line-height: 1px;
    visibility: hidden;
    position: relative;
    z-index: -1;
}

div.spinner {
    // z-index: 10000;
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-color: var(--bg);
    color: var(--fg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // font-size: 200%;
    font-family: var(--sans-serif);
    font-weight: 900;
    text-transform: uppercase;
    padding: 15px;
}
div.top.rating {
    background-color: var(--charts-accent);
    padding: 40px 20px 40px 20px;

    min-height: 100vh;

    div.content {
        display: flex;
        background-color: var(--bg);
        flex-direction: column;
        padding: 0 20px 80px 20px;
        border-radius: 25px;
        border: 4px solid var(--fg);

        div.label {
            margin-top: -30px;
            background-color: var(--fg);
            font-family: Impact, sans-serif;
            color: var(--bg);
            font-size: 32px;
            padding: 5px 16px 8px 16px;
        }

        svg.top10logo {
            width: 60%;
            margin: 20px 0;
        }

        div.legend {
            display: flex;
            flex-wrap: wrap;
            width: 58%;
            justify-content: center;
            border-top: 3px solid var(--charts-accent);
            border-bottom: 1px solid var(--fg);
            margin: 4px 0 12px 0;
            > div { display: flex; }
            > div > div {
                display: flex;
                svg { width: 20px; height: 20px; flex-shrink: 0; }
                font-family: Inter, sans-serif;
                font-size: 12px;
                line-height: 20px;
                margin: 6px 10px 6px 10px;
                > span {
                    display: inline-block;
                    margin-left: 4px;
                }
            }
        }

        div.table {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            div.chart {
                flex-grow: 1;
                flex-basis: 50%;
                max-width: 460px;
            }
        }
    }
}

div.chart {
    font-family: Inter, Arial, sans-serif;

    // max-width: 900px;

    > div {
        display: flex;
    }

    --pos-padding: 4px;
    --type-padding: 8px;

    div.head {

        margin: 20px;

        border: 2px solid var(--fg);
        border-radius: 8px;
        > div {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 13px;
            border-right: 2px solid var(--fg);
            &:last-child {
                border-right: none;
            }
        }

        div.pos {
            width: calc(41px + var(--pos-padding) * 2);
            justify-content: center;
            // align-items: center;
        }

        div.count {
            background-color: var(--fg);
            color: var(--bg);
            text-align: center;
            padding: 2px 0;
            font-size: 11px;
            line-height: 14px;
            width: calc(41px + var(--pos-padding) * 2);
            flex-shrink: 0;
            justify-content: center;
        }

        div.icon {
            background-color: var(--fg);
            width: calc(38px + 4px);
            padding: 2px;
            svg.icon {
                width: 30px;
                height: 30px;
                g.contour {
                    fill: none;
                    stroke-width: 1.1;
                    stroke: var(--bg);
                    > g { stroke: var(--bg); }
                }
            }
        }

        div.name {
            justify-content: center;
            flex-grow: 1;
        }

        div.type {
            width: calc(24px + var(--type-padding) * 2);
            justify-content: center;
        }

    }

    div.list {
        flex-direction: column;

        margin: 0 20px;

        div.entry {

            display: flex;

            margin-bottom: 20px;
            padding-top: 6px;
            border-top: 2px solid var(--fg);
            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            div.pos {
                position: relative;
                width: calc(41px + var(--pos-padding) * 2);
                height: calc(41px + var(--pos-padding) * 2);
                flex-shrink: 0;
                > svg,
                > div {
                    position: absolute;
                }
                > div {
                    width: 41px;
                    height: 41px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                > div.first {
                    font-weight: bold;
                    font-size: 18px;
                    color: var(--bg);
                    span {
                        position: relative;
                        left: -1px;
                        top: -1px;
                    }
                }
            }

            div.count {
                font-weight: bold;
                width: calc(41px + var(--pos-padding) * 2);
                height: calc(41px + var(--pos-padding) * 2);
                flex-shrink: 0;
                padding: 0 2px;
                &.like {
                    width: calc(38px + 4px);
                    padding: 0 2px;

                }
            }

            div.name {
                flex-grow: 1;
                justify-content: start;
                font-weight: 500;
                font-style: italic;
            }

            div.type {
                width: calc(24px + var(--type-padding) * 2);
                flex-shrink: 0;
                padding: var(--type-padding);
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    svg {
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
        &.little.star {
            width: 17px;
            height: 17px;
            fill: var(--fg);
        }
        &.big.star {
            width: 41px;
            height: 41px;
        }
        &.red {
            fill: var(--charts-accent);
        }
        &.round {
            width: 41px;
            height: 41px;
            circle {
                fill: none;
                stroke: var(--fg);
            }
        }
    }
}

@media (max-device-width: 640px) and (orientation: portrait) { 
    div.top {
        // min-height: 100vh;
        // height: 100%;
        // height: -webkit-fill-available;
        padding: 25px 12px 40px 12px;
        div.content {
            padding: 0 10px 10px 10px;
            div.label {
                font-size: 26px;
                margin-top: -22px;
                font-family: "Roboto Condensed";
            }
            svg.top10logo {
                width: 100%;
                margin: 20px 0;
            }
            div.legend {
                width: 100%;
            }
            div.table {
                div.head {
                    margin: 10px 0;
                }
                div.list {
                    margin: 0;
                }
            }
        }
    }
}


svg.roza {
    g.contour {
        fill: none;
        stroke-width: 1.1;
        > g { stroke: var(--fg); }
    }
    &.selected {
        g.contour {
            g.stebli { stroke: var(--reactions-roza-contour-stebli); }
            g.lepestki { stroke: var(--reactions-roza-contour-lepestki); }
        }
    }
}

svg.zhopa {
    g.contour {
        fill: none;
        stroke-width: 1.1;
        stroke: var(--fg);
    }
    &.selected {
        g.contour {
            stroke: var(--reactions-zhopa-contour);
        }
    }
}
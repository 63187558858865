div.reactions {

    display: flex;

    button.like {
        background-color: var(--bg);
        padding: 10px;
        border: none;
        &:disabled {
            opacity: .2;
        }
        svg.icon {
            width: 32px;
            height: 32px;
        }
    }

    div.counter {
        font-family: var(--sans-serif);
        display: inline-block;
    }
}

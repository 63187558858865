div.dialog {
    div.popup {
        z-index: 1002;
        background-color: var(--bg);
        padding: 10px;
        background-clip: padding-box;
        border-radius: 10px;
        border: 1px rgba(var(--rgb-fg), .1) solid;
        > div {
            margin-bottom: 15px;
        }
    }
    div.cover {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1001;
        left: 0; top: 0;
        background-color: var(--bg);
        // touch-action: none;
        opacity: .66;
    }
}

@media (max-device-width: 640px) and (orientation: portrait) { 
    div.dialog {
        div.popup {
            border: none;
        }
        div.cover {
            opacity: .95;
        }
    }
}

div.navbar {
    z-index: 1000;
    background-clip: padding-box;
    background-color: rgba(var(--rgb-bg), 1);
    position: fixed;
    height: calc(100vh - var(--headMargin));
    width: var(--navbarWidth);
    bottom: 0; right: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;

    div.ein, div.romochka, div.text, div.user {
        font-family: var(--sans-serif);
        font-size: 36px;
        font-weight: bold;
        a { color: var(--fg); }
    }

    div.ein, div.romochka {
        span.active {
            color: var(--lighter);
        }
        a {
            text-decoration: underline;
        }
    }

    div.romochka {
        transform: rotate(-90deg);
        position: absolute;
        top: 160px;
        left: -20px;
        // transform-origin: 112px 100px;
    }

    div.user {
        display: flex;
        a { display: flex; }
        transform: rotate(-90deg);
        transform-origin: bottom right;
    }

    div.text {
        
        position: absolute;
        top: 320px;
        span.active {
            border: 2px solid var(--lighter);
            color: var(--lighter);
            width: 60px;
            height: 60px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
        a {
            border: 2px solid var(--fg);
            width: 60px;
            height: 60px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
    }

}

@media (max-device-width: 1024px) and (orientation: portrait) { 
    div.navbar {
        height: var(--navbarHeight);
        border-top: 1px rgba(var(--rgb-fg), .1) solid;
        width: 100vw;
        bottom: 0; left: 0;
        right: unset;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding:  0 0 20px 0;

        div.ein, div.romochka, div.text, div.user {
            font-size: 20px;
        }

        div.romochka, div.text {
            transform: unset;
            position: unset; top: unset; left: unset;
        }

        div.user {
            transform: unset;
        }
        
        div.text { 
            margin-top: 4px;
            span.active, a {
                width: 32px;
                height: 32px;
            }
        }

    }
}

@media (prefers-color-scheme: dark) and (max-device-width: 1024px) and (orientation: portrait) {
    div.navbar {
        border-top: 1px rgba(var(--rgb-fg), .3) solid;
        background-color: rgba(var(--rgb-bg), .8);
    }
}

div.app {
    flex-grow: 1;
    min-height: 100vh;
    // height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: var(--navbarWidth);
}

@media (max-device-width: 1024px) and (orientation: portrait) { 
    div.app {
        padding-right: unset;
        padding-bottom: var(--navbarHeight);
    }
}

div.feed {
    div.new.post {
        div.personEditor {
            div.select {
                &.first {
                    div.list {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                &.second {
                    div.list {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        width: 200px;
                    }
                }
            }
        }
    }
}

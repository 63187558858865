div.new.post {
    div.menu {

        padding: 0 var(--readerMarginRight) 0 var(--readerMarginLeft);

        font-family: var(--sans-serif);

        div.level {

            div.items {
                display: flex;
                flex-wrap: wrap;

                div.item {
                    margin: 0 10px 10px 0;
                    padding: 6px 12px;
                    cursor: pointer;
                    &:last-child { margin-right: 0; }
                    &.selected {
                        cursor: text;
                        background-color: var(--bgAccent);
                        color: var(--fgAccent);
                        border-radius: 6px;
                    }
                    &.label {
                        cursor: text;
                        font-style: italic;
                        color: var(--bgDim);
                        &.selected {
                            background-color: unset;
                            color: var(--bgDim);

                        }
                    }
                    &.active {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

div.cms {
    font-family: "Inter", sans-serif;
    div.select {
        div.option {
            cursor: pointer;
            &.selected {
                cursor: default;
                background-color: var(--bgAccent);
                color: var(--fgAccent);
            }
        }
    }
}

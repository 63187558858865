
div.cms.user {

    padding: 80px;

    div.tools {
        div.form {
            padding: 10px;
            border: 1px solid var(--bgDim);
            border-radius: 8px;
            display: inline-block;
            margin: 0 10px 10px 0;
            input {
                font-size: 18px;
                margin-right: 6px;
                width: 360px;
            }
        }
        > button {
            margin: 0 10px 10px 0;
        }
    }

    button {
        background-color: var(--bgDim);
        border-radius: 4px;
        padding: 10px 20px;
        border: none;
        &:disabled {
            background-color: var(--bgLight);
            text-decoration: line-through;
        }
    }
}
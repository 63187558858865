div.foam {
    font-family: var(--sans-serif);
    div.newBubble {
        margin-top: 2rem;
        div.blob {
            margin: 12px 0;
        }
        div.users, div.bubbles {
            display: flex;
            flex-wrap: wrap;
        }
        div.users {
            // margin: 12px 0;
            h1 {
                font-size: 18px;
                width: 100%;
            }
            div.user {
                cursor: pointer;
                padding: 8px 12px;
                margin: 0 12px 12px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                text-align: center;
                font-size: 12px;
                width: 120px;
                min-height: 88px;
                svg.emoji {
                    width: 32px;
                    height: 32px;
                    margin-bottom: 6px;
                }
                &.selected {
                    cursor: unset;
                    font-size: 13px;
                    svg.emoji {
                        width: 96px;
                        height: 96px;
                        margin-bottom: 10px;
                    }
                }
                &.unselected {
                    font-size: 11px;
                    svg.emoji {
                        width: 24px;
                        height: 24px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        div.bubbles {
            margin-top: 0;
            h2 {
                font-size: 18px;
                width: 100%;
                margin-top: 0;
            }
            div.bubble {
                padding: 12px 18px 14px 18px;
                margin: 0 12px 12px 0;
                max-width: 16rem;
                border-radius: var(--radius);
                cursor: pointer;
                background-color: var(--bgLight);
                &.selected {
                    cursor: unset;
                    background-color: var(--bgAccent);
                    color: var(--fgAccent);
                }
            }
        }
        div.buttons {
            margin-top: 2rem;
            button {
                margin: 0 12px 12px 0;
            }
        }
    }

    h1 {
        margin-bottom: 0;
    }

    div.counter {
        div.count {
            font-size: 14px;
            font-weight: normal;
            margin-right: 15px;
            white-space: nowrap;
            display: inline-block;
            span.label::after { content: ": "; }
        }
    }

    div.bubbles {
        margin-top: 2rem;
        div.bubble {
            display: flex;
            align-items: flex-end;
            margin: 0 0 20px 0;
            max-width: 26rem;
            svg.emoji {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                margin-right: 20px;
            }
            div.msg {
                position: relative;
                background-color: var(--bgLight);
                padding: 8px 12px;
                border-radius: var(--radius);
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: 15px;
                line-height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    bottom: 0;
                    left: -19px;
                    polygon {
                        fill: var(--bgLight);
                    }
                }
                div.count {
                    font-size: 12px;
                    line-height: 12px;
                    padding: 4px 8px;
                    margin-top: 4px;
                    height: 20px;
                    background-color: var(--bgDim);
                    border-radius: 10px;
                }
            }
        }
    }
    a.top10 {
        font-family: var(--serif);
        display: inline-block;
        background-color: var(--charts-accent);
        color: white;
        padding: 2px 12px 4px 12px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 900;
        margin: 1em 0;
        border-radius: 7px;
        transform: skew(-8deg);
    }
    
}

@media (max-device-width: 1024px) and (orientation: portrait) {
    div.foam {
        div.bubbles {
            max-width: unset;
        }
        div.newBubble {
            div.bubbles {
                flex-direction: column;
                align-items: flex-start;
                div.bubble {
                    max-width: unset;
                }
            }
        }
    }
}
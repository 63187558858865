div.feed {
    max-width: 800px;
    align-self: center;
    margin: 20px 40px;

    div.new.post {
        text-align: center;
        margin: 0 0 40px 0;
        div.menu {
            div.level {
                h1 {
                    font-size: 20px;
                }
                div.items {
                    justify-content: center;
                }

                &.emotions {
                    div.items {
                        div.item {
                            font-size: 24px;
                        }
                    }
                }
                &.subjects {
                    div.items {
                        div.item {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        div.expressions {
            div.variants {
                padding: 0 var(--readerMarginRight) 0 var(--readerMarginLeft);
                h2 {
                    font-family: var(--sans-serif);
                    font-size: 16px;
                    margin: 0 0 12px 0;
                }
            }
            div.expression {
                div.editor {
                    padding: 0 var(--readerMarginRight) 0 var(--readerMarginLeft);

                    margin-top: 40px;

                    h2 {
                        font-family: var(--sans-serif);
                        font-size: 16px;
                        margin: 0 0 12px 0;
                    }

                    div.text {
                        font-family: var(--sans-serif);
                        font-size: 18px;
                        text-align: left;
                        line-height: 38px;
                    }
                }

                div.preview {
                    margin-top: 40px;
                    h2 {
                        font-family: var(--sans-serif);
                        font-size: 16px;
                        padding: 0 var(--readerMarginRight) 0 var(--readerMarginLeft);
                    }
                }
            }
        }

        button.button {
            border: none;
            > div {
                padding: 9px 18px 10px 18px;
            }
        }

        div.buttons {
            margin: 40px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            button { margin: 0 8px; }
            button.button.submit,
            button.button.cancel {
                min-width: 140px;

                border: none;
                // &:disabled { border: none }
                > div {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            button.button.cancel {
                > div {
                    padding: 9px 16px 10px 16px;
                }
            }
            button.button.submit {
                > div {
                    padding: 10px 16px 11px 16px;
                }
            }
        }
    }

    div.personEditor {
        padding: 0 var(--readerMarginRight) 0 var(--readerMarginLeft);
        margin: 40px 0;
        font-family: var(--sans-serif);
        font-size: 18px;
        h2 {
            font-size: 16px;
        }
    }

    div.posts {
        margin: 20px 0;

        div.post:nth-child(5n + 1) {
            background-color: var(--bgMozaic1);
        }
        div.post:nth-child(5n + 2) {
            background-color: var(--bgMozaic2);
        }
        div.post:nth-child(5n + 3) {
            background-color: var(--bgMozaic3);
        }
        div.post:nth-child(5n + 4) {
            background-color: var(--bgMozaic4);
        }
        div.post:nth-child(5n + 5) {
            background-color: var(--bgMozaic5);
        }
        
    }

    div.posts, div.new.post div.expression div.preview {
        div.post {

            text-align: left;

            background-color: var(--bgMozaic1);
            // margin: 20px 0;
            padding: 13px 16px 28px 16px;
            border-radius: 18px;

            &.your {
                border-left: 3px solid var(--bgBrightAccent);
                padding-left: 13px;
            }

            div.header {
                font-family: var(--sans-serif);
                font-size: 14px;
                display: flex;
                // justify-content: space-between;

                > div { white-space: nowrap; }

                div.person {
                    margin-left: -2px;
                    display: flex;
                    max-width: unset;
                    width: unset;
                    flex-grow: 1;
                    svg.emoji {
                        width: 17px;
                        height: 17px;
                        margin-right: 7px;
                    }
                }
                button.delete {
                    background-color: var(--bgBrightAccent);
                    color: var(--fgAccent);
                    &:disabled {
                        cursor: default;
                        border: none;
                        // background-color: var(--bgLight);
                        opacity: .2;
                        color: var(--bg);
                    }
                    &.confirm.no { background-color: var(--bgBrightAccentDesaturated); }
                }
                div.time {
                    font-size: 12px;
                    color: var(--light);
                    margin-left: 10px;
                }
            }

            div.text {
                font-family: var(--serif);
                font-size: 17px;
                margin: 20px 0;
            }

            div.likes {
                display: inline-flex;
                align-items: center;
                margin-right: 14px;
                button.like {
                    padding: 0;
                    border-radius: 0;
                    background: none;
                    border: none;
                    height: 20px;
                    svg.icon {
                        width: 20px;
                        height: 20px;
                    }
                }
                div.counter {
                    font-family: var(--sans-serif);
                    font-size: 12px;
                    line-height: 14px;
                    margin-left: 6px;
                    min-width: 18px;
                    text-align: left;
                    &.dislike {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}



@media (max-device-width: 640px) and (orientation: portrait) {
    div.feed {
        max-width: unset;
        margin: 20px 0;

        div.new.post {
            text-align: center;
            div.menu {
                div.level {
                    h1 {
                        font-size: 18px;
                    }
                }
            }
        }

        div.posts {
            margin: 20px 0;
        }

        div.posts, div.new.post div.expression div.preview {
            div.post {
                border-radius: 0;

                &.your {
                    border-left: 7px solid var(--bgBrightAccent);
                    padding-left: 9px;
                }

                div.likes {
                    button.like {
                        height: 26px;
                        svg.icon {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
            }
        }
    }

}

.bui {
    --divListBorderRadius: 12px;
    --divListBg: var(--lighter);
    --divItemPadding: 6px 12px 7px 12px;
    --divItemSelectedBg: var(--bgAccent);
    --divItemSelectedFg: var(--fgAccent);
    --divItemBorderRadius: 6px;

    --divPopupBorderRadius: 6px;
    --divPopupItemPadding: 6px 12px 7px 12px;
    --divPopupItemLineHeight: 130%;
    --divPopupItemMaxWidth: 15em;
    --divPopupListBg: var(--lighterOpaque);
}

div.bui.select {
    // border: 1px solid black;
    display: inline-flex;
    flex-direction: column;

    div.item {
        line-height: 130%;
    }

    div.pocket {
        position: relative;
        padding: 0 16px 0 8px;
        // height: 36px;
        overflow: hidden;

        div.item.selected {
            background-color: var(--bgAccent);
            color: var(--fgAccent);
            cursor: pointer;
            opacity: .55;
        }

        > div {
            position: absolute;
            padding: var(--divItemPadding);
            // left: 8px;
        }
        &.top {
            // border-bottom: 1px solid var(--hairline);
            > div {
                // bottom: 0;
                // border-bottom-left-radius: 0;
                // border-bottom-right-radius: 0;
            }
        }
        &.bottom {
            // border-top: 1px solid var(--hairline);
            > div {
                // top: 0;
                // border-top-left-radius: 0;
                // border-top-right-radius: 0;
            }
        }
    }

    div.list {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-direction: column;

        div.item {
            cursor: pointer;
            &.selected {
                background-color: var(--divItemSelectedBg);
                color: var(--divItemSelectedFg);
                cursor: auto;
            }
        }
    }



    &.pocket {
        div.list {
            height: 240px;
            padding: 8px 9px 8px 8px;
            background-color: var(--divListBg);
            border-radius: var(--divListBorderRadius);
            div.item {
                padding: var(--divItemPadding);
            }
        }
        div.item.selected {
            border-radius: var(--divItemBorderRadius);
        }
    }

    &.popup {

        div.list {
            border-radius: var(--divPopupBorderRadius);
            background-color: var(--divPopupListBg);
            div.item {
                padding: var(--divPopupItemPadding);
                line-height: var(--divPopupItemLineHeight);
                max-width: var(--divPopupItemMaxWidth);
                &:active {
                    background-color: var(--divItemSelectedBg);
                    color: var(--divItemSelectedFg);
                }
            }
        }
    }

    /* div.list::after {
        // firefox
        // https://stackoverflow.com/questions/29986977/firefox-ignores-padding-when-using-overflowscroll
        content: "";
        height: 8px;
        display: block;
        flex-shrink: 0;
    } */
}

div.bui.popup.button {
    display: inline-flex;
    padding: var(--divPopupItemPadding);
    border-radius: var(--divPopupBorderRadius);
    background-color: var(--divListBg);
    cursor: pointer;
    line-height: var(--divPopupItemLineHeight);
}

div.bui.line.select {
    font-family: var(--sans-serif);
    flex-direction: row;
    div.item {
        padding: 5px 11px 6px 11px;
        cursor: pointer;
        &.selected {
            background-color: var(--bgAccent);
            color: var(--fgAccent);
            border-radius: 5px;
            cursor: auto;
        }
    }
}
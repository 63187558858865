div.reader {
    display: flex;
    flex-grow: 1;
    // align-items: center;
    justify-content: center;
    div.page {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // background-color: var(--bgLight);
        // padding: 30px 30px 40px 30px;
        // max-width: 70%;

        &.invisible {
            display: none;
        }

        // width: 75%;

        // div.content { width: }

        &.left,
        &.right {
            div.content {
                align-items: flex-start;
            }
            width: 50%;
            max-width: unset;
            --readerMarginRight: 0;
        }
        div.foam {
            margin: 5rem var(--readerMarginRight) 0 var(--readerMarginLeft);
            max-width: 42rem;
            // background-color: wheat;
        }
    }
    div.lang {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    div.toc {
        display: flex;
        max-width: 900px;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 0 0 0;
        div.item {
            a {
                text-decoration: none;
                color: var(--fg);
            }
            width: 405px;
            div.ill {
                width: 405px;
                height: 220px;
                background-size: cover;
                background-position: center;
                div.shadow {
                    height: 220px;
                    background: -moz-linear-gradient(top,  rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.07) 100%);
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.07) 100%);
background: linear-gradient(to bottom,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.07) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03000000', endColorstr='#12000000',GradientType=0 );

                }
            }
            h1 {
                font-family: var(--serif);
                font-size: 20px;
                margin: 6px 20px 40px 10px;
                min-height: 60px;
            }
        }
    }
}

div.pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 12px;
    font-family: var(--sans-serif);
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    .page {
        padding: 8px 16px;
    }

    .page.toc {
        // font-size: 32px;
        text-decoration: none;
    }

    a.page {
        color: var(--fg);
    }

    div.page.selected {
        color: red;
    }
}

div.render {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 12px;
}

@media (max-device-width: 1024px) {
}

@media (max-device-width: 640px) and (orientation: portrait) {
    div.reader {
        div.toc {
            div.item {
                width: 100vw;
                div.ill {
                    width: 100vw;
                }
            }
        }
    }
}

@media (max-device-width: 800px) and (orientation: landscape) {
}
